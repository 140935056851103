import React, { useState } from "react"
import CMSRender from "aldoo-ra/CMS/cms-render"
import { showNotification } from "aldoo-ra/Notification"
import Typography from "aldoo-ra/Typography"
import Button from "aldoo-ra/Button"
import { api } from "aldoo-ra/API"

const newsletterAPI = api("newsletter")

const NewsletterSubscribeSection = () => {
  const [email, setEmail] = useState("")
  const [isFocused, setIsFocused] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (isSubmitting) return
    setIsSubmitting(true)

    try {
      const response = await newsletterAPI({
        subscribe: {
          email,
        },
      })

      if (response.error) {
        showNotification({
          message: response.error,
          className: "bg-red-500 text-white",
        })
        return
      }

      showNotification({
        message:
          response.message ||
          "You have successfully subscribed to our newsletter!",
        className: "bg-primary text-white",
      })

      setEmail("")
    } catch (error) {
      showNotification({
        message: "Failed to subscribe. Please try again later.",
        className: "bg-red-500 text-white",
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  // Standalone render function for CMSRender
  const renderNewsletterContent = (data) => {
    return (
      <div className="relative flex flex-col w-full md:w-[700px] h-[410px] md:h-[480px] lg:h-[520px] mx-auto items-center justify-end lg:pb-[110px] mb-16 px-4 md:px-0">
        <img
          src={data.background_graphic}
          className="absolute w-full md:w-full max-w-[380px] md:max-w-full md:h-full object-contain bottom-12 md:-bottom-[100px] left-1/2 md:left-[20px] lg:left-[-60px] -translate-x-1/2 md:translate-x-0 -z-10 scale-[1] md:scale-[0.7] lg:scale-[1.9] xl:scale-[2.1]"
          alt="Background graphic"
        />

        {/* Content */}
        <div className="lg:h-auto w-full max-w-[460px] md:w-[560px] lg:max-w-[560px] flex flex-col items-center justify-between">
          <div className="flex flex-col items-center w-full">
            <Typography
              component="h2"
              variant="h1"
              className="text-center mb-6"
            >
              {data.title}
            </Typography>

            {/* Subtitle */}
            <Typography className="w-full text-center text-base lg:text-[20px] text-text leading-normal tracking-custom_1 font-normal mt-0 md:mt-4 lg:mt-0 mb-[56px] md:mb-8">
              {data.subtitle}
            </Typography>
          </div>

          {/* Subscribe Form */}
          <form
            onSubmit={handleSubmit}
            className=" w-full md:w-[500px] lg:w-[560px] xl:max-w-[582px] mt-[50px] lg:mt-0"
          >
            <div
              className={`h-[60px] flex items-center justify-between rounded-full pl-[13px] pr-2 md:mt-[80px] lg:mt-0 transition-colors duration-200 ${
                isFocused ? "bg-primary" : "bg-primary_lt"
              }`}
            >
              <div className="flex-1 flex items-center gap-3 px-2">
                <div className="flex-1 min-w-0">
                  {/* iOS-compatible input */}
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    placeholder={data.placeholder}
                    required
                    disabled={isSubmitting}
                    style={{
                      width: "100%",
                      outline: "none",
                      appearance: "none",
                      WebkitAppearance: "none",
                      MozAppearance: "none",
                      border: "none",
                      background: "transparent",
                      fontSize: "16px",
                      padding: "8px 0",
                      margin: "0",
                      opacity: "1",
                      WebkitTextFillColor: isFocused ? "#ffffff" : "#000000",
                      color: isFocused ? "#ffffff" : "#000000",
                    }}
                    className={`${
                      isFocused ? "placeholder-white" : "placeholder-primary"
                    }`}
                  />
                </div>
              </div>
              <Button
                type="submit"
                variant="primary_s"
                disabled={isSubmitting}
                className={`flex-shrink-0 justify-end min-w-[130px] mr-[5px] md:mr-0 transition-colors duration-200 ${
                  isFocused ? "bg-white !text-primary" : ""
                }`}
              >
                {isSubmitting ? "Subscribing..." : data.cta}
              </Button>
            </div>
          </form>
        </div>
      </div>
    )
  }

  return (
    <CMSRender
      contentType="NewsletterSubscribe"
      renderItem={renderNewsletterContent}
    />
  )
}

export default NewsletterSubscribeSection
