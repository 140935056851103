import React, { useState, useEffect, useRef, useMemo, useCallback } from "react"
import CMSData from "aldoo-ra/CMS/cms-data"
import Typography from "aldoo-ra/Typography"
import parse from "html-react-parser"
import Localization from "aldoo-ra/Localization"

const TestimonialCard = React.memo(({ testimonial, index, width, height }) => {
  return (
    <div
      id={"testimonial-card-" + index}
      className="testimonial-card absolute bg-white shadow-custom_card rounded-[18px] p-4 sm:p-6 md:p-8"
      style={{
        width,
        height,
        userSelect: "none",
      }}
    >
      {/* Pic and Name */}
      <div className="flex flex-row gap-4 sm:gap-6 items-center">
        <img
          src={testimonial.profilePicture}
          alt={`${testimonial.name}'s profile`}
          className="w-[40px] sm:w-[47px] md:w-[64px] h-[40px] sm:h-[47px] md:h-[64px] object-cover rounded-full"
        />

        <Typography className="text-primary text-2xl sm:text-[36px] leading-tight tracking-custom_1 font-bold my-0">
          {testimonial.name}
        </Typography>
      </div>

      {/* Testimonial */}
      <div className="mt-3 sm:mt-4">
        <Typography className="text-[13px] sm:text-[15px] leading-normal tracking-custom_1 font-normal text-text">
          {parse(testimonial.testimonial)}
        </Typography>
      </div>
    </div>
  )
})

const Testimonials = () => {
  const { currentLanguage } = Localization()
  const containerRef = useRef(null)
  const cardsRenders = useRef([])
  const velocity = useRef(0)
  const isDragging = useRef(false)
  const dragOffset = useRef(0)
  const autoScrollTimer = useRef(0)
  const lastClientX = useRef(0)
  const lastClientY = useRef(0) // Track Y position
  const initialClientX = useRef(0) // Store initial X on touch/mouse down
  const initialClientY = useRef(0) // Store initial Y on touch/mouse down
  const isHorizontalDrag = useRef(false) // Flag to determine scroll direction
  const hasDirectionLocked = useRef(false) // Flag to track if direction is determined
  const updateCardsInterval = useRef(null)
  const lastDimensions = useRef(null)

  // Add hydration flag
  const [isHydrated, setIsHydrated] = useState(false)

  // Set hydration flag after mount
  useEffect(() => {
    setIsHydrated(true)
  }, [])

  // Fixed initial dimensions to avoid hydration mismatch
  // We'll use the desktop/tablet dimensions for SSR to ensure consistency
  const initialDimensions = {
    CARD_WIDTH: 386,
    CARD_HEIGHT: 351,
    CARD_GAP: 24,
  }

  // Responsive Card Dimensions - only used after hydration
  const getCardDimensions = useCallback(() => {
    // If we're server-side or not yet hydrated, use the initial dimensions
    if (typeof window === "undefined" || !isHydrated) {
      return initialDimensions
    }

    const screenWidth = window.innerWidth

    if (screenWidth >= 1080) {
      // Large screens (desktop)
      return {
        CARD_WIDTH: 386,
        CARD_HEIGHT: 351,
        CARD_GAP: 32,
      }
    } else if (screenWidth >= 768) {
      // Medium screens (tablet)
      return {
        CARD_WIDTH: 386,
        CARD_HEIGHT: 351,
        CARD_GAP: 24,
      }
    } else {
      // Mobile dimensions
      return {
        CARD_WIDTH: 350,
        CARD_HEIGHT: 311,
        CARD_GAP: 16,
      }
    }
  }, [isHydrated])

  // Testimonials state and data fetching
  const {
    data: cmsTestimonials,
    loading,
    error,
    refresh,
  } = CMSData({
    contentType: "Testimonial",
    manual: true,
  })

  useEffect(() => {
    refresh()
  }, [currentLanguage])

  // Initialize with stable initial dimensions to prevent hydration mismatch
  const [cardDimensions, setCardDimensions] = useState(initialDimensions)

  // Memoized testimonials preparation
  const prepareTestimonials = useCallback(
    (data, dimensions) => {
      if (loading || !containerRef.current || !data.length) return []

      const { CARD_WIDTH, CARD_GAP } = dimensions
      const numberOfVisibleItems = Math.ceil(
        containerRef.current.offsetWidth / (CARD_WIDTH + CARD_GAP)
      )

      const repeat = Math.ceil(numberOfVisibleItems / data.length) + 1

      const availableTestimonials = []
      for (let i = 0; i < repeat; i++) {
        availableTestimonials.push(...data)
      }

      return availableTestimonials
    },
    [loading]
  )

  // Stabilize testimonials state
  const [testimonials, setTestimonials] = useState([])

  // Update testimonials when CMS data or dimensions change - but only after hydration
  useEffect(() => {
    // Skip any updates until hydration is complete
    if (!isHydrated) return

    // Avoid unnecessary updates
    const currentDimensions = getCardDimensions()
    const dimensionsChanged =
      !lastDimensions.current ||
      lastDimensions.current.CARD_WIDTH !== currentDimensions.CARD_WIDTH ||
      lastDimensions.current.CARD_HEIGHT !== currentDimensions.CARD_HEIGHT ||
      lastDimensions.current.CARD_GAP !== currentDimensions.CARD_GAP

    // Update dimensions if changed
    if (dimensionsChanged) {
      lastDimensions.current = currentDimensions
      setCardDimensions(currentDimensions)
    }

    // Prepare testimonials only when data is loaded and dimensions are set
    if (!loading && cmsTestimonials.length > 0) {
      const preparedTestimonials = prepareTestimonials(
        cmsTestimonials,
        currentDimensions
      )

      // Only update if testimonials have changed
      if (
        JSON.stringify(preparedTestimonials) !== JSON.stringify(testimonials)
      ) {
        setTestimonials(preparedTestimonials)
      }
    }
  }, [
    loading,
    cmsTestimonials,
    prepareTestimonials,
    getCardDimensions,
    isHydrated,
  ])

  // Handle responsive resizing - only after hydration
  useEffect(() => {
    // Skip if not hydrated yet
    if (!isHydrated) return

    const handleResize = () => {
      const newDimensions = getCardDimensions()

      // Only update if dimensions have actually changed
      const dimensionsChanged =
        !lastDimensions.current ||
        lastDimensions.current.CARD_WIDTH !== newDimensions.CARD_WIDTH ||
        lastDimensions.current.CARD_HEIGHT !== newDimensions.CARD_HEIGHT ||
        lastDimensions.current.CARD_GAP !== newDimensions.CARD_GAP

      if (dimensionsChanged) {
        lastDimensions.current = newDimensions
        setCardDimensions(newDimensions)
      }
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [getCardDimensions, isHydrated])

  const { CARD_WIDTH, CARD_HEIGHT, CARD_GAP } = cardDimensions
  const MAX_SCROLL_VELOCITY = 1
  const AUTO_SCROLL_AFTER_TIME = 4000
  const DIRECTION_THRESHOLD = 5 // Lower threshold to determine scroll direction faster

  // Position cards after testimonials or dimensions change - only after hydration
  useEffect(() => {
    // Skip if not hydrated yet
    if (!isHydrated || !testimonials.length) return

    cardsRenders.current = Array.from(
      document.querySelectorAll(".testimonial-card")
    )

    cardsRenders.current.forEach((card, index) => {
      card.style.left = index * (CARD_WIDTH + CARD_GAP) + "px"
    })

    velocity.current = -MAX_SCROLL_VELOCITY
  }, [testimonials, CARD_WIDTH, CARD_HEIGHT, CARD_GAP, isHydrated])

  // Drag and scroll logic - only after hydration
  useEffect(() => {
    // Skip if not hydrated yet
    if (!isHydrated || !containerRef.current) return

    const getCoordinates = (e) => {
      return {
        x: e.clientX || (e.touches && e.touches[0].clientX) || 0,
        y: e.clientY || (e.touches && e.touches[0].clientY) || 0,
      }
    }

    const getMovementX = (e) => {
      const { x } = getCoordinates(e)
      const movementX = x - lastClientX.current
      lastClientX.current = x
      return movementX
    }

    const onEnd = (e) => {
      if (!isDragging.current) return

      isDragging.current = false
      hasDirectionLocked.current = false
      isHorizontalDrag.current = false

      const scrollDir =
        lastClientX.current - initialClientX.current > 0 ? 1 : -1

      autoScrollTimer.current = setTimeout(() => {
        velocity.current = MAX_SCROLL_VELOCITY * scrollDir
      }, AUTO_SCROLL_AFTER_TIME)
    }

    const onStart = (e) => {
      const coords = getCoordinates(e)

      initialClientX.current = coords.x
      initialClientY.current = coords.y
      lastClientX.current = coords.x
      lastClientY.current = coords.y

      isDragging.current = true
      hasDirectionLocked.current = false
      isHorizontalDrag.current = false
      velocity.current = 0
    }

    const onMove = (e) => {
      if (!isDragging.current) return

      if (autoScrollTimer.current) clearTimeout(autoScrollTimer.current)

      const coords = getCoordinates(e)

      // If direction hasn't been determined yet
      if (!hasDirectionLocked.current) {
        const deltaX = Math.abs(coords.x - initialClientX.current)
        const deltaY = Math.abs(coords.y - initialClientY.current)

        // Only lock direction after we've moved enough to determine intent
        if (deltaX > DIRECTION_THRESHOLD || deltaY > DIRECTION_THRESHOLD) {
          isHorizontalDrag.current = deltaX > deltaY
          hasDirectionLocked.current = true

          // If this is a horizontal drag, prevent default on this first significant movement
          // This must happen before the browser commits to a scroll
          if (isHorizontalDrag.current && e.cancelable) {
            e.preventDefault()
          }
        }
      }

      // If this is a horizontal drag, handle it
      if (isHorizontalDrag.current) {
        // Only try to prevent default if the event is still cancelable
        if (e.cancelable) {
          e.preventDefault()
        }

        dragOffset.current = getMovementX(e)
        velocity.current = 0
        updateCards()
      }
      // Otherwise let the event propagate for vertical scrolling
      else {
        dragOffset.current = 0
      }

      // Update last Y position
      lastClientY.current = coords.y
    }

    const containerEl = containerRef.current

    // Mouse events
    containerEl.addEventListener("mousedown", onStart)
    containerEl.addEventListener("mouseup", onEnd)
    containerEl.addEventListener("mouseleave", onEnd)
    containerEl.addEventListener("mousemove", onMove)

    // All touch events must be non-passive to properly handle scrolling direction
    containerEl.addEventListener("touchstart", onStart, { passive: false })
    containerEl.addEventListener("touchend", onEnd, { passive: true })
    containerEl.addEventListener("touchcancel", onEnd, { passive: true })
    containerEl.addEventListener("touchmove", onMove, { passive: false })

    return () => {
      // Remove mouse events
      containerEl.removeEventListener("mousedown", onStart)
      containerEl.removeEventListener("mouseup", onEnd)
      containerEl.removeEventListener("mouseleave", onEnd)
      containerEl.removeEventListener("mousemove", onMove)

      // Remove touch events
      containerEl.removeEventListener("touchstart", onStart)
      containerEl.removeEventListener("touchend", onEnd)
      containerEl.removeEventListener("touchcancel", onEnd)
      containerEl.removeEventListener("touchmove", onMove)
    }
  }, [CARD_WIDTH, CARD_GAP, isHydrated])

  // Memoized update cards function
  const updateCards = useCallback(() => {
    let vel = velocity.current
    if (vel > MAX_SCROLL_VELOCITY) vel = MAX_SCROLL_VELOCITY
    if (vel < -MAX_SCROLL_VELOCITY) vel = -MAX_SCROLL_VELOCITY

    const cardArray = cardsRenders.current

    if (!cardArray.length) return

    let lastPosition = parseInt(cardArray[cardArray.length - 1].style.left)
    let firstPosition = parseInt(cardArray[0].style.left)

    cardArray.forEach((card) => {
      if (!isDragging.current) {
        card.style.left = parseInt(card.style.left) + vel + "px"
      } else {
        card.style.left = parseInt(card.style.left) + dragOffset.current + "px"
      }

      // Left side culling
      if (
        (vel < 0 || dragOffset.current < 0) &&
        parseInt(card.style.left) < -CARD_WIDTH * 2 - CARD_GAP - vel
      ) {
        lastPosition = lastPosition + CARD_WIDTH + CARD_GAP
        card.style.left = lastPosition + "px"
      }

      // Right side culling
      if (
        (vel > 0 || dragOffset.current > 0) &&
        parseInt(card.style.left) > window.innerWidth + CARD_WIDTH * 2
      ) {
        firstPosition = firstPosition - CARD_WIDTH - CARD_GAP
        card.style.left = firstPosition + "px"
      }
    })

    cardArray.sort((a, b) => {
      return parseInt(a.style.left) - parseInt(b.style.left)
    })

    let lastCardRight = parseInt(cardArray[0].style.left)
    cardArray.forEach((card) => {
      card.style.left = lastCardRight + "px"
      lastCardRight += CARD_WIDTH + CARD_GAP
    })

    cardsRenders.current = cardArray
  }, [CARD_WIDTH, CARD_GAP])

  // Continuous update effect - only after hydration
  useEffect(() => {
    // Skip if not hydrated yet
    if (!isHydrated) return

    // Clear any existing interval
    if (updateCardsInterval.current) {
      clearInterval(updateCardsInterval.current)
    }

    // Set up new interval
    updateCardsInterval.current = setInterval(() => {
      if (!isDragging.current) updateCards()
    }, 16)

    // Clean up interval on unmount
    return () => {
      if (updateCardsInterval.current) {
        clearInterval(updateCardsInterval.current)
      }
    }
  }, [updateCards, isHydrated])

  // Render cards - using a fixed height that matches what we'll use for SSR
  const renderCards = useMemo(() => {
    // Calculate the height that will be consistent for both SSR and CSR
    // Always use 401px which corresponds to the desktop/tablet card height + 50px
    const consistentHeight = 401

    return (
      <div style={{ height: `${consistentHeight}px` }}>
        {testimonials.map((testimonial, index) => (
          <TestimonialCard
            key={index}
            testimonial={testimonial}
            index={index}
            width={CARD_WIDTH}
            height={CARD_HEIGHT}
          />
        ))}
      </div>
    )
  }, [testimonials, CARD_WIDTH, CARD_HEIGHT])

  return (
    <div>
      <div
        className={`w-full overflow-hidden relative p-3 sm:p-5`}
        ref={containerRef}
      >
        {renderCards}
      </div>
    </div>
  )
}

export default Testimonials
