import Testimonials from "./Testimonials"
import Text from "aldoo-ra/Text"

export default function TestimonialsSection() {
  return (
    <div className="flex flex-col mt-20 mb-[100px] md:mt-[100px] md:mb-10 lg:mt-40">
      <Text 
        id="testimonials_title" 
        component="h2"
        variant="h1" 
        className="w-[320px] md:w-auto text-center mb-[46px] mx-auto md:mx-0" 
      />

      <Testimonials />
    </div>
  )
}
