import React, { useState, useEffect, useRef } from "react"
import CMSData from "aldoo-ra/CMS/cms-data"
import Typography from "aldoo-ra/Typography"
import parse from "html-react-parser"
import Value from "aldoo-ra/Value"

const sizeToMinRatio = {
  big: 440 / 320, // Minimum ratio for big cards
  medium: 1, // Base ratio
  small: 240 / 320, // Minimum ratio for small cards
}

// Maximum heights for each card size
const maxCardHeights = {
  big: 540,
  medium: 480,
  small: 420,
}

const Card = ({ card, cardHeights, isHeightCalculated, index }) => {
  const cardRef = useRef(null)
  const [size] = Array.isArray(card.size) ? card.size : [card.size]
  const imageSize = size === "big" ? "w-9/10" : "w-3/5"
  const [imageLoaded, setImageLoaded] = useState(false)
  const isMobile = typeof window !== "undefined" && window.innerWidth < 1080

  // During measurement phase, maintain minimum heights
  const initialHeightClass = !isHeightCalculated
    ? size === "big"
      ? "min-h-[540px]"
      : "min-h-[420px]"
    : ""

  // Calculate the final height based on the maximum height for this card type
  const getFinalHeight = () => {
    // For mobile, directly return the desired fixed heights
    if (isMobile) {
      if (size === "big") return "438px"
      if (size === "small") return "350px"
      return "350px" // medium cards on mobile
    }

    // For desktop
    if (!isHeightCalculated || !cardHeights) return "auto"
    const height = cardHeights[size]
    // Ensure minimum ratios are maintained and maximum heights are enforced
    const minHeight = cardHeights.medium * sizeToMinRatio[size]
    const maxHeight = maxCardHeights[size]
    return `${Math.min(Math.max(height, minHeight), maxHeight)}px`
  }

  // Calculate height for the image container to ensure text has enough space
  const getImageContainerHeight = () => {
    if (isMobile) {
      // Reserve space for text and padding
      // Measure text height more accurately by getting a reference to the text div
      const textHeight = 120 // Approximated text height + margins
      const paddingHeight = 16 // 8px top + 8px bottom padding

      if (size === "big") return `${438 - textHeight - paddingHeight}px`
      if (size === "small") return `${350 - textHeight - paddingHeight}px`
      return `${350 - textHeight - paddingHeight}px` // medium cards
    }
    return "auto" // Default to auto for desktop
  }

  return (
    <div
      ref={cardRef}
      data-card-size={size}
      data-index={index}
      data-image-loaded={imageLoaded}
      className={`w-full max-w-[464px] ${initialHeightClass} flex flex-col bg-theme_white text-text rounded-[24px] p-8 md:p-10 transition-all duration-300`}
      style={{
        boxShadow: "0px 0px 18px rgba(0, 0, 0, 0.2)",
        height: getFinalHeight(),
        maxHeight:
          isMobile && size === "big" ? "438px" : isMobile ? "350px" : "auto",
      }}
    >
      {/* Image Container - with explicitly controlled height on mobile */}
      <div
        className="flex items-center justify-center overflow-hidden"
        style={{
          height: isMobile ? getImageContainerHeight() : "auto",
          flexGrow: isMobile ? "0" : "1",
        }}
      >
        <img
          src={card.icon}
          alt={card.title}
          className={`${imageSize} h-auto object-contain max-h-full`}
          onLoad={() => setImageLoaded(true)}
        />
      </div>

      {/* Text content - fixed below the red line */}
      <div className="text-text">
        <Typography
          variant="body_24_accent"
          className="!text-[18px] lg:!text-[20px] xl:!text-[24px] text-text mt-0 mb-4"
        >
          {parse(card.title)}
        </Typography>
        <Typography variant="info_16" className="!text-[16px] my-0">
          {card.description}
        </Typography>
      </div>
    </div>
  )
}

const Column = ({ children }) => (
  <div className="flex flex-col w-full gap-6 lg:gap-12 ">{children}</div>
)

const distributeCardsToColumns = (cards, gridCols) => {
  const columns = Array.from({ length: gridCols }, () => [])
  if (!Array.isArray(cards)) return []

  cards.forEach((card, idx) => {
    columns[idx % gridCols].push({ ...card, originalIndex: idx })
  })
  return columns
}

const HomeFeatureCardsSection = ({ grid_cols = 2 }) => {
  const [cardHeights, setCardHeights] = useState(null)
  const [isHeightCalculated, setIsHeightCalculated] = useState(false)
  const containerRef = useRef(null)
  const [platform] = Value("site_platform")
  const [contentID, setContentID] = useState()

  const { data, loading } = CMSData({
    contentType: "AquaSiteCardGroup",
    match: {
      "content.id": platform
        ? "feature_cards_" + platform
        : "feature_cards_android",
    },
  })

  const calculateHeights = () => {
    if (!containerRef.current) return

    const cards = containerRef.current.querySelectorAll("[data-card-size]")
    if (!cards.length) return

    // Get natural heights for each card size
    const heightsBySize = {
      big: [],
      medium: [],
      small: [],
    }

    cards.forEach((card) => {
      const size = card.getAttribute("data-card-size")
      const contentHeight = card.scrollHeight
      heightsBySize[size].push(contentHeight)
    })

    // Calculate maximum height for each size independently
    const maxHeights = {
      big: Math.max(...heightsBySize.big.filter((h) => h > 0), 0),
      medium: Math.max(...heightsBySize.medium.filter((h) => h > 0), 0),
      small: Math.max(...heightsBySize.small.filter((h) => h > 0), 0),
    }

    // Ensure minimum ratios are maintained and maximum heights are enforced
    const adjustedHeights = {
      medium: Math.min(maxHeights.medium, maxCardHeights.medium),
      big: Math.min(
        Math.max(maxHeights.big, maxHeights.medium * sizeToMinRatio.big),
        maxCardHeights.big
      ),
      small: Math.min(
        Math.max(maxHeights.small, maxHeights.medium * sizeToMinRatio.small),
        maxCardHeights.small
      ),
    }

    // On mobile, heights are directly set in the Card component's getFinalHeight function
    // This ensures the exact heights we want (438px for big, 350px for small) rather than calculated values
    if (window.innerWidth < 768) {
      adjustedHeights.medium = 350
      adjustedHeights.big = 438
      adjustedHeights.small = 350
    }

    setCardHeights(adjustedHeights)
    setIsHeightCalculated(true)
  }

  useEffect(() => {
    // Add resize event listener
    window.addEventListener("resize", calculateHeights)

    return () => {
      window.removeEventListener("resize", calculateHeights)
    }
  }, [])

  // Use MutationObserver to detect when cards and images are added to the DOM
  useEffect(() => {
    if (!containerRef.current) return

    // Function to check if all images in cards are loaded
    const checkAllImagesLoaded = () => {
      if (!containerRef.current) return false

      const cards = containerRef.current.querySelectorAll("[data-card-size]")
      if (!cards.length) return false

      // Check if all images in cards have loaded
      const allImagesLoaded = Array.from(cards).every(
        (card) => card.getAttribute("data-image-loaded") === "true"
      )

      return allImagesLoaded
    }

    // Create a MutationObserver to watch for DOM changes
    const observer = new MutationObserver(() => {
      // When DOM changes, check if content is ready for height calculation
      if (checkAllImagesLoaded()) {
        calculateHeights()
        // Once heights are calculated based on loaded content, can disconnect observer
        observer.disconnect()
      }
    })

    // Start observing with configuration
    observer.observe(containerRef.current, {
      childList: true, // Watch for card additions/removals
      subtree: true, // Watch the entire subtree
      attributes: true, // Watch for attribute changes
      attributeFilter: ["data-image-loaded"], // Only care about image load status
    })

    // Run initial calculation if content is already loaded
    if (checkAllImagesLoaded()) {
      calculateHeights()
      observer.disconnect()
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  // Additionally, trigger height calculation when CMS content becomes available
  useEffect(() => {
    // This will run after initial render and when containerRef is populated
    if (containerRef.current) {
      const cards = containerRef.current.querySelectorAll("[data-card-size]")
      if (cards.length > 0) {
        // Content is available, attempt an initial calculation
        // If images aren't loaded yet, the MutationObserver will handle it
        calculateHeights()
      }
    }
  }, [containerRef.current])

  // Recalculate heights on window resize to properly handle mobile/desktop transitions
  useEffect(() => {
    const handleResize = () => {
      calculateHeights()
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const renderContent = (section) => {
    const columns = distributeCardsToColumns(section.cards, grid_cols)

    return (
      <div
        ref={containerRef}
        className="w-full max-w-[1008px] flex flex-col items-center justify-start px-4 mb-32 lg:mb-[184px] mx-auto "
      >
        <div className="flex flex-col md:flex-row gap-6 lg:gap-12">
          {columns.map((columnCards, colIdx) => (
            <Column key={colIdx}>
              {columnCards.map((card, idx) => (
                <Card
                  key={card.originalIndex}
                  card={card}
                  index={card.originalIndex}
                  cardHeights={cardHeights}
                  isHeightCalculated={isHeightCalculated}
                />
              ))}
            </Column>
          ))}
        </div>
      </div>
    )
  }

  if (!data || !data[0]) return null

  return renderContent(data[0])
}

export default HomeFeatureCardsSection
